@import './constants/constants.scss';

@font-face {
    font-family: tex-bold;
    src: url('/fonts/texgyreadventor-bold.otf');
}
@font-face {
    font-family: tex-bolditalic;
    src: url('/fonts/texgyreadventor-bolditalic.otf');
}
@font-face {
    font-family: tex-italic;
    src: url('/fonts/texgyreadventor-italic.otf');
}
@font-face {
    font-family: tex-regular;
    src: url('/fonts/texgyreadventor-regular.otf');
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    * {
        //font-family: tex-regular !important;
        font-family: 'Open Sans', sans-serif !important;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.MuiGrid-root {
    flex-grow: 1 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.select {
    height: 35px;
    @media only screen and (max-width: $screen-sm) {
        max-width: 190px;
    }
}

.bold {
    font-weight: bold !important;
}

.italic {
    font-style: italic;
}

.grid-row {
    padding: 1rem !important;
}

h2.subtitle {
    font-size: 18px;
    font-weight: bold;
    &.small {
        font-size: 14px;
    }
    &.medium {
        font-size: 18px;
    }
    &.large {
        font-size: 20px;
    }
}

h3.section-subtitle {
    font-size: 15px;
    font-weight: 500;
    &.small {
        font-size: 12px;
    }
    &.medium {
        font-size: 14px;
    }
    &.large {
        font-size: 16px;
    }
}

h4.heading-level-4 {
    font-size: 12px;
    font-weight: bold;
    &.small {
        font-size: 12px;
    }
    &.medium {
        font-size: 14px;
    }
    &.large {
        font-size: 16px;
    }
}

p.regular-text {
    font-size: 12px;
    &.small {
        font-size: 10px;
    }
    &.medium {
        font-size: 14px;
    }
    &.large {
        font-size: 16px;
    }
    &.xl {
        font-size: 18px;
    }
    &.xl-2 {
        font-size: 20px;
    }
    &.xl-3 {
        font-size: 22px;
    }
}

p.regular-text-medium {
    font-size: 14px;
}

span.MuiTabs-indicator {
    background-color: $PRIMARY_COLOR;
}

.left-top-round {
    border-top-left-radius: 15px;
}
.left-bottom-round {
    border-bottom-left-radius: 15px;
}

.right-top-round {
    border-top-right-radius: 15px;
}

.right-bottom-round {
    border-bottom-right-radius: 15px;
}

.blue {
    color: $PRIMARY_COLOR;
}

.orange {
    color: $SECONDARY_COLOR;
}

/*common css*/
.mt-5 {
    margin-top: 3rem;
}

.mt-3 {
    margin-top: 1rem;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: start !important;
}

.m-auto {
    margin: 0 auto !important;
}

.text-bold {
    font-weight: 600 !important;
}
.custom-popup {
    min-width: 600px;
    width: 100%;
}
.img-fluid {
    max-width: 100%;
}
.text-center {
    text-align: center;
}
.btn-primary {
    background: #ffa348 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0.375rem 1.5rem !important;
}

.btn-primary-outlined {
    border-color: #ffa348 !important;
    color: #ffa348 !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0.375rem 1.5rem !important;
}

.btn-secondary-outlined {
    border-color: $PRIMARY_COLOR !important;
    color: $PRIMARY_COLOR !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0.375rem 1.5rem !important;
}

.btn-contained,
.btn.btn-contained {
    border: 2px solid $PRIMARY_COLOR;
    font-size: 12px;
    background-color: $PRIMARY_COLOR;
    color: white;
    &:hover {
        background-color: $SECONDARY_COLOR;
        color: $PRIMARY_BLACK;
        border: 2px solid $SECONDARY_COLOR;
    }
}

.custom-select {
    padding: 0 1rem;
    color: #ffffff !important;
    border-radius: 0.8rem;

    label,
    input {
        color: #ffffff !important;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
        border-color: white !important;
    }
}

.close {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    float: right;
    cursor: pointer;
}

.section-light-grey-bg {
    background: $SECONDARY_BG_COLOR;
}

.section-white-bg {
    background: $PRIMARY_WHITE;
}

.d-flex {
    display: flex;
}

.d-flex-center {
    align-items: center;
    display: flex;
}

/*css for radio button*/
.MuiRadio-colorSecondary.Mui-checked {
    color: $SECONDARY_COLOR !important;
}

.MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: rgba(221, 179, 82, 0.04) !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(221, 179, 82, 0.04) !important;
}

.full-width {
    width: 100%;
}

.max-width {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    padding: 0px 0px;
}

.simple-link {
    text-decoration: none;
    color: $PRIMARY_BLACK;
}

.alert-collapse {
    z-index: 10002;
    position: fixed;
    top: 0;
}

.no-margin {
    margin: 0 !important;
}

.no-border {
    border: none !important;
}

.MuiAutocomplete-popper {
    z-index: 10002 !important;
}
