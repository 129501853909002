@import '../../../../constants/constants.scss';
$TAG_TEXT_COLOR: #fff;
.about-ch {
    background-color: $PRIMARY_WHITE;
    .about-ch-img-container {
        margin: 25px 0;
        height: 500px;
        justify-content: center;

        @media only screen and (max-width: $screen-sm) {
            height: 250px;
        }

        @media only screen and (max-width: $screen-xs) {
            height: 140px;
        }
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
}