@import '../../constants/constants.scss';
$STICKY_NAV_BG_COLOR: #484848;

.appbar.ch-nav {
    position: relative;
}

.course-page {
    background-color: $PRIMARY_WHITE;

    .subtitle {
        font-size: 20px;
        font-weight: 600;
        padding: 16px;
        text-align: center;
    }

    .tick-round-icon,
    .cancel-round-icon {
        color: #fff;
        border-radius: 50%;
        background-color: $SUCCESS_GREEN;
    }
    .cancel-round-icon {
        background-color: $DANGER_RED;
    }
    .course-grid-row {
        padding: 1rem;

        &.tab-container {
            color: $PRIMARY_WHITE;
            position: sticky;
            background-color: $PRIMARY_WHITE;
            box-shadow: 0 2px 4px #e9e9e9;
            top: -1px;
            height: 50px;
            padding: 0;
            z-index: 10001;
            overflow: auto;
            display: flex;
            align-items: center;
        }

        &.ribbon {
            background-image: url('/images/coding-hero/enquire-now-section-bg2.png');
            color: $PRIMARY_WHITE;
            .ribbon-text {
                font-size: 22px;
                font-weight: bold;
                @media only screen and (max-width: $screen-xs) {
                    font-size: 18px;
                }
            }
            .download-brochure-btn {
                color: $PRIMARY_WHITE;
                font-weight: bold;
                background-color: $PRIMARY_COLOR;
                border-radius: 15px;
                width: 200px;
                @media only screen and (max-width: $screen-xs) {
                    width: 120px;
                }
            }
            .full-width-button {
                background-color: $PRIMARY_COLOR;
                color: $PRIMARY_WHITE;
            }
        }

        &#kids-develop {
            background-color: $PRIMARY_WHITE;
        }

        &.faq-row,
        &.popular-courses-row {
            background-color: $PRIMARY_WHITE;
            .section-title-container {
                padding: 1rem 0;
                margin: 15px 0;
            }
        }

        &.ch-experience {
            background-color: $PRIMARY_WHITE;
            .ch-experience-img-container {
                background-image: url('/images/coding-hero/codinghero-experience.png');
                background-repeat: no-repeat;
                background-size: contain;
                margin: 25px 0;
                height: 700px;
                background-position: center;

                @media only screen and (max-width: $screen-sm) {
                    height: 325px;
                }

                @media only screen and (max-width: $screen-xs) {
                    height: 200px;
                }
            }
        }

        &.batch-grid {
            background-color: $SECONDARY_BG_COLOR;
        }

        .course-grid-content {
            margin: 25px 0;
        }
    }

    .enquire-btn {
        background: #ffa348;
        color: #ffffff;
        border-radius: 50rem;
        font-size: 12px;
        font-weight: 600;
        padding: 0.375rem 1.5rem;
        position: absolute;
        top: 134px;
        right: 15px;
        z-index: 20000;

        @media only screen and (max-width: $screen-sm) {
            top: 92px;
        }

        @media only screen and (max-width: $screen-xs) {
            top: 75px;
        }
    }

    #mentors {
        background-color: $SECONDARY_BG_COLOR;
    }

    .fixed-menu-button {
        text-transform: none;
        font-size: 14px;
        color: #666;
        display: inline-block;
        line-height: 20px;
        font-weight: 600;
        //padding: 20px 0;
        position: relative;
        margin: 0;
        margin-right: 20px;
    }
    .button-display-none {
        display: none;
    }

    .ch-certificate-img-container {
        background-image: url('/images/course-page/certificate-template.jpeg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 25px 0;
        height: 700px;
        background-position: center;

        @media only screen and (max-width: $screen-sm) {
            height: 325px;
        }

        @media only screen and (max-width: $screen-xs) {
            height: 200px;
        }
    }
    .certificate-arrow {
        height: 300px;
        margin-left: 200px;
    }
}

.download-brochure-form {
    z-index: 10002 !important;
    .cancel-round-icon {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .input-text {
        margin: 5px 0;
    }
}
