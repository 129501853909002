@import '../../../constants/constants.scss';

.payment-course-card {
    .header {
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 3rem;
    }
    .outer-box {
        border-radius: 0.25rem !important;
        background-color: $PRIMARY_WHITE;
        border: 1px solid $CARD_BORDER_COLOR;
        min-height: 100px;
        position: relative;
        margin: 0.5rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

        .course-name-section {
            min-height: 40px;
            font-size: 14px;
            font-weight: bold;
        }
        .course-fee {
            font-size: 16px;
            min-height: 40px;
            .f-12 {
                font-size: 12px;
            }
        }
    }
    .selectedPlan {
        box-shadow: inset 0 0 0 3px #3eb24f, 0 0 3px #393d3a;
        border: none;
        background: #f1f1f2;
    }
}
