@import '../../../constants/constants.scss';

.course-payment-card.outer-box {
    box-shadow: 0 0 8px 5px $PRIMARY_GRAY;
    background-color: $PRIMARY_WHITE;
    min-height: 240px;
    height: auto;
    //margin-right:20px;
    margin: 16px;
    .regular-text {
        padding: 5px;
    }
    &.selected {
        box-shadow: 0 0 8px 0px $SUCCESS_GREEN;
    }
}
