@import '../../../../constants/constants.scss';

.top-ribbon {
    height: auto;
    background-color: $SECONDARY_BG_COLOR;

    .certificate {
        height: 65px;
        padding: 1rem 0;
        img {
            @media only screen and (max-width: $screen-xs) {
                height: 100px;
            }
        }
    }
    .top-ribbon-item {
        //height: 90px;
        margin-top: 10px;
        @media only screen and (min-width: $screen-xs) {
            border-right: 2px solid $SECONDARY_GRAY;
        }
    }
}
