@import '../../constants/constants.scss';

.privacy-page {
    .header {
        padding: 1rem;
        height: 300px;
        background-image: url('https://acadgild.com/photos/Privacy-Policy-1-min.jpg');
    }
    
    .category-page-main-content {
        .privacy-page-header{
            font-size: 31px;
            font-weight: 600;
            background-color: #fff;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        
        .privacy-title-sub {
            border-radius: 4px;
            font-size: 18px;
            text-align: right !important;
            font-weight: 300;
        }
        .privacy-page-content .p{
            font-size: 16px;
            margin-block-start: 0px !important;
        }
        
        
    }

}