@import '../../../constants/constants.scss';

.home-course-card {
    .header {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 3rem;
    }
    .outer-box {
        border-radius: 0.25rem !important;
        background-color: $PRIMARY_WHITE;
        border: 1px solid $CARD_BORDER_COLOR;
        min-height: 300px;
        position: relative;
        margin: 0 1rem;

        @media only screen and (min-width: $screen-md) {
            max-width: 320px;
            margin: 0 auto;
        }

        @media only screen and (max-width: $screen-sm) {
            margin-bottom: 1.5rem;
        }

        .course-name-section {
            min-height: 80px;
            color: #5d69ff;
            font-size: 16px;
            font-weight: bold;
        }
        .course-fee {
            color: #ffa348;
            font-size: 16px;
            min-height: 80px;
            font-weight: bold;
            padding: 0 10px;

            .f-12 {
                font-size: 12px;
            }
        }
        .course-ribbon-section {
            min-height: 200px;
        }
        .ribbon3 {
            width: 150px;
            height: 35px;
            line-height: 35px;
            padding-left: 15px;
            position: absolute;
            left: -10px;
            bottom: 25px;
            background: #3fdbb1;
            color: $PRIMARY_WHITE;
            font-size: 14px;
            font-weight: bold;
        }
        .ribbon3:before,
        .ribbon3:after {
            content: '';
            position: absolute;
        }
        .ribbon3:before {
            height: 0;
            width: 0;
            top: -8.5px;
            left: 0.1px;
            border-bottom: 9px solid black;
            border-left: 9px solid transparent;
        }
        .ribbon3:after {
            height: 0;
            width: 0;
            right: -14.5px;
            border-top: 21px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 15px solid #3fdbb1;
        }
    }
}
