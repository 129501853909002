@import '../../constants/constants.scss';

.category-page {
    .hero {
        padding: 1rem;
        height: 150px;
        background-image: url('https://acadgild.com/photos/banner_about_us.png');

        .hero-title {
            font-size: 26px;
            color: $PRIMARY_WHITE;
        }

        .hero-subtitle {
            font-size: 18px;
            color: $PRIMARY_WHITE;
        }
    }
    .category-page-main-content {
        .category-count-container {
            .section-subtitle {
                @media only screen and (min-width: $screen-md) {
                    position: absolute;
                    top: 25px;
                }
            }
        }
        position: relative;
        min-height: 540px;
        @media only screen and (max-width: $screen-md) {
            min-height: 640px;
        }
        .category-container {
            .left-panel-container {
                padding: 1rem;
                position: absolute;
                top: 0;
                width: 350px;
                max-height: 540px;
                overflow: auto;
                @media only screen and (min-width: $screen-sm) {
                    background-color: $BG_COLOR_GRAY;
                }
            }
            .cards-wrapper {
                margin: 0 -8px;
            }
        }
    }
}
