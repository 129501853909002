@import '../../constants/constants.scss';

#simple-menu {
    z-index: 10002 !important;
}

.appbar.ch-nav {
    background-color: $PRIMARY_COLOR;
    .tab {
        min-width: 0;
        opacity: 1;
    }
    .text-right {
        text-align: right;
    }
}

.login-dialog {
    z-index: 10004 !important;
    margin: auto;
    .email-field {
        margin-bottom: 15px;
    }

    @media only screen and (min-width: $screen-md) {
        width: 50%;
    }
}

.header-tabs.appbar-menu {
    text-decoration: none;
    .tab {
        color: $LOGGED_IN_TEXT;
    }
}

.header-drawer {
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorTop.MuiPaper-elevation16 {
        background-color: $LOGGED_OUT_BG_COLOR;
        color: $LOGGED_OUT_TEXT;
        top: 57px;

        .header-tabs.appbar-menu {
            text-decoration: none;
            .tab {
                color: $LOGGED_OUT_TEXT;
                text-align: justify;
                opacity: 1;
            }
        }
    }
}

.header-icons {
    color: $LOGGED_OUT_TEXT;
    &.cancel-icon {
        @media only screen and (max-width: $screen-md) {
            padding: 6px 12px;
            position: absolute;
            top: 5px;
            right: 0;
        }
    }
}
.custom-menu-btn {
    .MuiButton-label {
        @media only screen and (max-width: $screen-md) {
            color: $PRIMARY_BLACK;
        }
    }
}
.custom-menu-dropdown {
    .MuiPopover-paper {
        top: 63px !important;
    }

    .MuiListItem-button:hover {
        color: $PRIMARY_WHITE;
        background: $PRIMARY_ORANGE;
    }
}
