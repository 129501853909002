@import '../../../../constants/constants.scss';

.comparison-section {
    background-color: $SECONDARY_BG_COLOR;
    .cell {
        height: 52px;
    }
    .comparision-parameters {
        .course-grid-row {
            background-color: $PRIMARY_COLOR;
            border-bottom: 1px solid $PRIMARY_GRAY;
            &.merge-bg {
                visibility: hidden;
            }
            .heading-level-4 {
                color: $PRIMARY_WHITE;
            }
        }
    }

    .codinghero-score,
    .others-score {
        box-shadow: 0px 0px 8px 2px;
        border-radius: 20px;
        z-index: 1;
        .course-grid-row {
            text-align: center;
            .heading-level-4 {
                width: 100%;
                font-weight: 400;
            }
            &.odd {
                background-color: $PRIMARY_GRAY;
            }
            &.even {
                background-color: $PRIMARY_WHITE;
            }
        }
        .class-head {
            .heading-level-4 {
                color: $PRIMARY_COLOR;
            }
            .regular-text {
                letter-spacing: 2px;
                vertical-align: text-bottom;
            }
        }
    }
    .codinghero-score {
        .class-head {
            background-color: $PRIMARY_WHITE;
            padding: 1rem 0;
            text-align: center;
            margin: auto;

            @media only screen and (max-width: $screen-sm) {
                padding: 1rem 0;
                .regular-text {
                    font-size: 12px;
                }
            }
        }

        .subtitle {
            width: 100%;
            color: $SECONDARY_COLOR;
        }
    }
    .others-score {
        z-index: 0;
        box-shadow: none;
        border-radius: none;

        .subtitle {
            width: 100%;
        }
    }
    .right-grid-bg {
        background-color: $PRIMARY_COLOR;
        margin: 52px 0px 55px 0px;
    }

    .heading-level-4 {
        font-weight: 400;
        @media only screen and (max-width: $screen-sm) {
            font-size: 12px;
        }
    }
}
