@import '../../../../constants/constants.scss';

.highlights {
    padding: 15px 0;
    background-color: $SECONDARY_COURSE_HIGHLIGHT_BG_COLOR;
    .highlight-item {
        .highlight-content {
            border-right: 1px solid $PRIMARY_COLOR;
            padding: 12px 0px 12px 16px;
            height: 100px;
            .highlight-text-large {
                font-size: 32px;
                font-weight: 300;
            }

            .highlight-text-small {
                font-size: 14px;
            }
        }
        &:last-child {
            .highlight-content {
                border: none;
            }
        }
    }
}
