@import '../../../../constants/constants.scss';

.mobile-footer {
    text-align: center;
    background-color: $PRIMARY_BLACK;
    border-top: 1px solid $PRIMARY_WHITE;
    position: fixed;
    bottom: 0;
    .has-border {
        border-right: 1px solid $PRIMARY_WHITE;
    }
    .full-width-button {
        width: 100%;
    }
    .footer-button-text {
        font-size: 12px;
        font-weight: bold;
        padding:5px;
    }
    span {
        color: $PRIMARY_WHITE;
    }
}
