@import '../../../../constants/constants.scss';

.syllabus {
    .tools-strip {
        margin: 25px 0;
        background-color: $SECONDARY_BG_COLOR;
        padding: 1rem;
        height: 140px;

        @media only screen and (max-width: $screen-sm) {
            height: auto;
        }

        .text-center-syllabus-icon-box {
            max-width: 130px;
            @media only screen and (max-width: $screen-sm) {
                max-width: unset;
            }
        }

        .caption {
            font-size: 14px;
            font-weight: bold;
        }
        .course-duration {
            text-align: center;
        }
        .word-cloud-container {
            height: 100px;
        }
    }
}
