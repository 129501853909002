@import '../../../constants/constants.scss';

.course-card.outer-box {
    border: 1px solid $CARD_BORDER_COLOR;

    @media only screen and (min-width: $screen-md) {
        max-height: 480px;
    }

    @media only screen and (max-width: $screen-xs) {
        max-height: unset;
        margin: 0;
    }

    .MuiGrid-root.MuiGrid-item {
        padding: 1rem;
        @media only screen and (max-width: $screen-sm) {
            padding: 10px;
        }
        &.divider-container-item {
            padding: 0;
        }
    }
    .card-header {
        background-color: $BG_COLOR_GRAY;
        max-height: 120px;
        .card-title-container {
            border-right: 1px solid $REGULAR_BORDER_COLOR;
            background-color: $BG_COLOR_GRAY;

            .subtitle {
                font-size: 16px;
            }

            .star-icon {
                color: $PRIMARY_COLOR;
            }
            .ratings-icon-container {
                padding: 1rem 0;
            }
            .ratings-container {
                padding-left: 0px;
                @media only screen and (max-width: $screen-sm) {
                    padding-top: 17px;
                }

                .ratings-number {
                    font-size: 14px;
                }
                .reviewers-number {
                    font-size: 9px;
                    padding-left: 2px;
                }
            }
        }
        .course-icon-container {
            position: relative;
            .tag {
                background-color: $TAG_BG_COLOR;
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;
                color: $TAG_TEXT_COLOR;
                padding: 2px;
                font-weight: bold;
            }
            .course-icon {
                margin: 12px auto;
            }
        }
    }
    .card-title-container {
        .course-program-title {
            font-size: 14px;
        }
    }
    .course-description-container {
        .pull-left,
        .pull-right {
            padding: 5px 0;
        }
        .pull-left {
            .MuiGrid-root.MuiGrid-item {
                border-right: 1px solid $CARD_BORDER_COLOR;
            }
        }
        .course-subtitle {
            font-size: 12px;
            font-weight: bold;
        }
        .course-description {
            font-size: 12px;
        }
    }
    .course-highlights-container {
        padding: 1rem;
        .MuiGrid-container {
            background-color: $SECONDARY_COURSE_HIGHLIGHT_BG_COLOR;
            padding: 5px;
            .course-highlight {
                border-right: 1px solid $PRIMARY_COLOR;
                padding: 10px;
                p {
                    font-size: 12px;
                }
            }
            :last-child {
                border: none;
            }
        }
    }
    .card-footer {
        .price-number {
            font-size: 22px;
            font-weight: 400;
            @media only screen and (max-width: $screen-sm) {
                font-size: 20px;
            }
        }
        .price-support-text {
            font-size: 12px;
        }
        .view-details-btn {
            border-radius: 0;
            border: 2px solid $PRIMARY_COLOR;
            font-size: 11px;
            color: $PRIMARY_COLOR;
        }
    }
}
