@import '../../constants/constants.scss';

.faq-section {
    .faq-container {
        padding: 1rem;
        margin: 5px;
    }

    .faq-title {
    }
}
