@import '../../constants/constants.scss';

.breadcrumb {
    //background-color: $PRIMARY_COLOR;
    padding: 0 35px;
    padding-top: 30px;
    z-index: 1;
    position: absolute;
    .breadcrumb-links {
        text-decoration: none;
        text-transform: capitalize;
        color: $PRIMARY_FONT_COLOR;
        font-size: 10px;
        &.active {
            color: $SECONDARY_COLOR;
            font-weight: bold;
        }
    }

    @media only screen and (max-width: $screen-xs) {
        padding: 0 5px;
    }
}
.breadcrumb-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
