@import '../../../../constants/constants.scss';

.interested-form {
    position: relative;
    text-align: center;
    .input-text {
        margin: 5px 0;
    }
    .plus-cc {
        padding: 18px 5px;
    }
    .cancel-round-icon {
        right: -10px;
        top: -10px;
        position: absolute;
        border-radius: 50%;
    }
}
