@import '../../constants/constants.scss';
$STICKY_NAV_BG_COLOR: #484848;
$CARD_BORDER_COLOR: #dee2e6;
$REGULAR_BORDER_COLOR: #000;

$TAG_TEXT_COLOR: #fff;
$COURSE_HIGHLIGHT_BG_COLOR: #fceaea;

.home-page {
    background-color: $SECONDARY_BG_COLOR;

    .section-gutter {
        padding: 3.125rem 0;
    }

    .home-page-row {
        //  padding: 1rem;
        .banner {
            min-height: 350px;
            background: #5d69ff;

            .subheading {
                padding-left: 3rem;
                padding-right: 3rem;
                background: #5d69ff;
                color: $TAG_TEXT_COLOR;
            }
            .banner-h1 {
                font-size: 30px;
                font-weight: 600;
                margin-top: 50px;
            }
            .banner-p {
                font-size: 20px;
                font-weight: 300;
                margin-top: 25px;
            }
            .header {
                background-size: 100% 100%;
                background-image: url('/images/coding-hero/home/top-banner.png');
            }
            .enquire-btn {
                background: #ffa348;
                color: #ffffff;
                border-radius: 50rem;
                font-size: 12px;
                font-weight: 600;
                padding: 0.375rem 1.5rem;
                margin-bottom: 5rem;
            }
        }

        .about-ch {
            padding: 50px 0 !important;

            .MuiGrid-container {
                justify-content: center !important;
                text-align: center;
                margin: 0 auto;
                width: 80%;
                flex-grow: 0 !important;
            }
            .subtitle {
                font-size: 30px !important;
                justify-content: center !important;
            }
            .about-ch-img-container {
                margin-bottom: 25px;
                max-width: 100%;
                height: auto;
            }

            .img-fluid {
                max-width: 100%;
                //height: auto;
            }
        }

        &.mentor-section {
            .MuiGrid-container {
                justify-content: center;
            }
        }

        .learning-attributes {
            padding: 50px 0;

            .attributes-container {
                justify-content: center;

                .attributes-text-container {
                    margin: 5px 20px;
                    text-align: center;
                }
            }

            h2.subtitle {
                font-size: 30px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 25px;
            }
        }

        &.mentor-section {
            padding: 50px 0;

            .MuiGrid-container {
                justify-content: center;
            }

            .heading {
                font-size: 30px;
                font-weight: 600;
                text-align: center;
                margin: 0 0 16px;
            }

            p {
                margin: 6px 0 5px;
            }
        }

        .learning-attributes {
            background-color: $PRIMARY_WHITE;
            .attributes-container {
                justify-content: center;
                margin: 25px 0;

                .attributes-text-container {
                    margin: 5px 20px;
                    text-align: center;
                }
            }
            .heading {
                font-size: 30px;
                font-weight: 600;
                text-align: center;
            }
        }
    }
}

.custom-popup {
    h2 {
        color: $PRIMARY_COLOR;
        font-size: 30px;
        letter-spacing: -0.04rem;
    }
    .input-text {
        margin-bottom: 1rem;
    }
    .input-select {
        margin-bottom: 1rem;
    }

    .btn-primary {
        margin-left: 1rem;
    }

    .close {
        text-align: right;
    }

    @media only screen and (max-width: $screen-sm) {
        .img-cntr {
            display: none;
        }
    }
}

.MuiDialog-paperWidthSm {
    min-width: 80% !important;
    max-width: 100% !important;
    padding: 1rem;
}

.bg-blue {
    background: $PRIMARY_COLOR;
    border: 1px solid $PRIMARY_COLOR;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: 0px auto 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50px;
        height: auto;
    }
}

@media only screen and (min-width: $screen-md) {
    .px-7 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }
}
