@import '../../constants/constants.scss';

.loader {
    background-color: $PRIMARY_WHITE;
    opacity: 0.9;
    position: fixed;
    z-index: 10002;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    img {
        position: absolute;
        left: calc(50% - 100px);
        top: calc(50% - 100px);
    }
}
