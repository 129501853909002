@import '../../constants/constants.scss';

.payment-section {
    background: $SECONDARY_BG_COLOR;
    padding: 2rem 0;
}

.p_heading {
    font-size: 1.25rem !important;
    padding: 1.875rem 0 0.625rem 1.25rem;
    font-weight: 400 !important;
}

.course-img {
    padding-right: 0.625rem;
    width: 50px;
}

.main-section {
    .input-txt {
        width: 100%;
        font-size: 0.875rem;
        margin: 0.5rem 0;
    }

    .accordion-cntr {
        width: 100%;
    }

    .MuiAccordion-root {
        width: 100%;
    }

    .summary {
        background-color: $PRIMARY_GRAY;
        border-bottom: 1px solid $PRIMARY_GRAY;
        display: flex;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        align-items: center;
    }

    .MuiAvatar-colorDefault {
        background: $PRIMARY_WHITE;
        color: $PRIMARY_BLACK;
        width: 25px;
        height: 25px;
        font-size: 1rem;
        margin-right: 1rem;
    }

    .details {
        padding: 0.625rem;
        padding: 2.5rem 3.125rem;

        .payment-plans {
            padding-right: 0.625rem;
            width: 30px;
            height: auto;
            vertical-align: middle;
            border-style: none;
            font-size: 1.25rem !important;
            font-weight: bold !important;
        }
        h2 {
            display: inline-block;
        }
        .align-right {
            text-align: right;
        }
    }
    .btn-contained {
        float: right;
        margin: 1rem 0 0 0;
    }

    .MuiDivider-inset {
        margin-right: 1rem;
    }
}

.plan-options {
    margin-top: 2rem;
}

.payment-tbl {
    .MuiTableRow-root:last-child .MuiTableCell-root {
        border-bottom: none;
    }
}

.payment-cntr {
    .course-img {
        position: absolute;
        left: 28px;
        width: 30px;
        top: 118px;
    }
}

.coupon-cntr {
    position: absolute;
    right: 50px;
    top: 115px;

    input {
        border: solid 1px #e3e3e3;
        font-size: 12px;
        padding: 7px 10px;
        border-radius: 5px;
    }

    a {
        text-decoration: underline;
        font-size: 12px;
        padding: 4px;
        color: #333;
        font-weight: 400;
    }
}

.MuiFormControlLabel-label {
    width: 100%;
}

.plus-cc {
    padding: 18px 14px;
}

@media only screen and (min-width: $screen-md) {
    .main-section .input-txt {
        margin: 0.5rem 2%;
    }

    .main-section .proceed-button {
        margin-right: 1rem;
    }

    .MuiTableCell-root:first-child {
        width: 40%;
    }
}

@media only screen and (max-width: $screen-xs) {
    .coupon-cntr {
        right: 60px;
        top: 75px;
    }
}
