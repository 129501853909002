@import '../../constants/constants.scss';

.category-panel {
    &.left-panel {
        .vertical-tabs {
            .course-link {
                .MuiTab-wrapper {
                    align-items: baseline;
                }
            }
            span.PrivateTabIndicator-colorSecondary-7 {
                background-color: $PRIMARY_COLOR;
            }
        }
    }
    &.top-panel {
        max-width: 190px;
        padding: 1rem;

        .MuiInputBase-root.MuiOutlinedInput-root {
            @media only screen and (max-width: $screen-xs) {
                max-width: 165px;
            }
        }
        .course-option {
            border-radius: 0;
        }
    }
}
