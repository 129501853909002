@import '../../../../constants/constants.scss';

.hero {
    //background-image: url('/images/course-page/ch-cover-canva.png');
    background: $SECTION_BG_BLUE;
    color:$PRIMARY_FONT_COLOR;
    padding-bottom: 24px;
    padding: 5px 0px;
    background-size: cover;
    min-height: 500px;
    //@media only screen and (min-width: $screen-xs) {
        //padding-left: 50px;
    //    height: 600px;

    //}
    @media (min-width: $screen-xs) {
        //padding-left: 50px;
        min-height: 550px;
    }
    @media (min-width: $screen-sm) {
        //padding-left: 50px;
        min-height: 500px;
    }

    .hero-title-text {
        font-weight: 400;
        font-size: 30px;
        margin-top:60px;
    }
    .hero-ratings-container {
        margin: 15px 0;
        .rating-container {
            border-radius: 5px;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
            background-color: #ffffff;
            padding: 10px 15px;
            margin: 11px 20px 0 0;
            vertical-align: top;
        }
        .ratings-icon-container {
            margin-right: 15px;

            .star-icon {
                color: $SECONDARY_COLOR;
            }
        }
       
        .ratings-number {
            font-size: 14px;
        }
        .reviewers-number {
            font-size: 10px;
            padding-left: 2px;
            padding-top:2px;
        }
        
    }
    .hero-text.subtitle {
        text-align: justify;
        font-weight: 400;
        font-size: 15px;
        line-height: 2;
        //color: $PRIMARY_WHITE;
    }
    .key-points-title {
        font-size: 15px;
        font-weight: bold;
        line-height: 2;
    }
    .key-points-text {
        font-size : 14px;
        font-weight: 400;
        //color: $PRIMARY_WHITE;
        line-height: 1.5;
        text-align: justify;
    }
    .key-points-container {
        padding-top: 16px;
        padding-right: 16px;
    }
    .key-points-icon{
        height: 35px;
    }
    .live-online {
        color: #FF5A51;
        font-weight: bold;
        font-size: 12px;
        vertical-align: middle;
        background: #ffffff;
        padding: 5px 5px 5px 12px;
        position: relative;
        box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
        margin-left: 15px;
        margin-top: 60px;
        margin-bottom: 20px;
        align-self: center;
    }
    .live-online::before {
        content: " ";
        height: 5px;
        width: 5px;
        position: absolute;
        background: #FF5A51;
        border-radius: 50%;
        left: 4px;
        top: 10px;
    }
}
