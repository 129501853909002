@import '../../../../constants/constants.scss';

.upcoming-batches {
    .MuiTypography-root {
        margin: 2px;
        padding: 2px;
    }
    .batch-tab {
        background-color: $PRIMARY_WHITE;
    }
    .padded-grid-upcoming-batches {
        padding: 1rem;
        background-color: $PRIMARY_WHITE;
        &.batches {
            &.active-batch {
                display: flex;
            }
        }
        .enroll-now {
            color: $PRIMARY_WHITE;
            font-weight: bold;
            background-color: $PRIMARY_COLOR;
        }
    }
}
