@import '../../../constants/constants.scss';

.project-card.outer-box {
    margin: 10px;
    background-color: $PRIMARY_WHITE;
    box-shadow: 0 0.5rem 1rem $SECONDARY_GRAY;
    border: 1px solid $CARD_BORDER_COLOR;
    padding: 2rem 1rem;
    width: 350px;
    height: 330px;
    .card-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .pill-container {
        margin: 2px 0;
        padding: 1px;
        max-height: 300px;
    }
    .card-divider {
        width: 25%;
        height: 2px;
        background-color: $PRIMARY_COLOR;
    }
    .card-content-container {
        max-height: 190px;
        overflow: auto;
    }
}
