@import '../../../../constants/constants.scss';

.learning-attributes {
    .attributes-title-container {
        margin: 25px 0;
    }
    .attributes-container {
        text-align: center;
        padding: 10px;
    }
}
