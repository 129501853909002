@import '../../constants/constants.scss';
$TAG_TEXT_COLOR: #fff;

.ribbons {
    background: url('/images/coding-hero/enquire-now-section-bg2.png') no-repeat;
    background-size: 100% 100%;
    color: $PRIMARY_WHITE;
    height: auto;
    padding: 1.4rem 0;
    justify-content: center;
    align-items: center;

    .ribbon-text {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .enquire-btn {
        text-align: center;
        background: $PRIMARY_COLOR;
        color: #fff;
        border-radius: 50rem;
        font-size: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-weight: 600;
        display: inline-block;
        margin-left: 2rem;
        height: 45px;
        text-transform: capitalize;

        &:hover {
            background: $PRIMARY_COLOR;
        }
    }
}

.blue-ribbon {
    .ribbons {
        background: url('/images/coding-hero/enquire-now-section-bg1.png')
            no-repeat;
        background-size: 100% 100%;
    }

    .enquire-btn {
        background: $ORANGE;

        &:hover {
            background: $ORANGE;
        }
    }
}
