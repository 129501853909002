@import '../../../../constants/constants.scss';

.ico-create {
    cursor: pointer;
    position: absolute;
    top: 55%;
    right: 50px;
}
.batch-details-cntr {
    width: 100%;

    .label {
        align-items: flex-start !important;
    }
}
.batch-list {
    border-bottom: 1px solid $PRIMARY_GRAY;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: block;

    p:first-child {
        font-size: 1.375rem;
        margin-bottom: 5px;
        font-weight: bold;
    }
}
