@import '../../../constants/constants.scss';

.mentor-card.outer-box {
    text-align: center;
    background-color: $PRIMARY_WHITE;
    box-shadow: 0 0.5rem 1rem $SECONDARY_GRAY;
    border-radius: 10px;
    padding: 20px;
    margin: 16px;
    height: 250px;
    //margin-right: 24px;

    .mentor-name {
        color: $ORANGE;
        font-weight: bold;
        font-size: 1.125rem;
    }

    .mentor-profile-text {
        margin-top: 15px;
        font-size: 12px;
    }
}
