$PRIMARY_COLOR: #5d67f6;
$SECONDARY_COLOR: #ddb352;

$LOGGED_IN_BG_COLOR: #fff;
$LOGGED_IN_TEXT: #fff;
$LOGGED_OUT_BG_COLOR: #fff;
$LOGGED_OUT_TEXT: #000;
$SUCCESS_GREEN: #1ba54a;
$DANGER_RED: #d9534f;
$SECONDARY_BG_COLOR: #f5f6ff;
$ORANGE: #ffa348;

$CARD_BORDER_COLOR: #dee2e6;
$REGULAR_BORDER_COLOR: #000;

$TAG_TEXT_COLOR: #fff;
$COURSE_HIGHLIGHT_BG_COLOR: #fceaea;

$PRIMARY_ORANGE: #f4a246;

$BG_COLOR_GRAY: #f4f4f4;
$PRIMARY_GRAY: #f4f4f4;
$SECONDARY_GRAY: #e5e5e5;

$PRIMARY_WHITE: #fff;
$PRIMARY_BLACK: #000;

$PRIMARY_FONT_COLOR: #4a4a4a;

$SECTION_BG_BLUE: #eff4ff;

$PRIMARY_BUTTON_BORDER_COLOR: #ddb352;
$TAG_BG_COLOR: #5dd7ea;

$SECONDARY_COURSE_HIGHLIGHT_BG_COLOR: #f5f6ff;

//$screen-lg: 1920px;
//$screen-md: 1280px;
//$screen-sm: 960px;
//$screen-xs: 600px;

$screen-xl: 1920px;
$screen-lg: 1280px;
$screen-md: 960px;
$screen-sm: 600px;
$screen-xs: 0px;
