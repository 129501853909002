@import '../../constants/constants.scss';

.accordian-container {
    border-radius: 6px;
    box-shadow: 0 0 3px 1px $SECONDARY_GRAY;
    .accordian {
        .accordian-details {
            background-color: $SECONDARY_BG_COLOR;
            border-left: 2px solid $PRIMARY_COLOR;
        }
    }
}
