@import '../../constants/constants.scss';
.footer {
    background-color: $PRIMARY_COLOR;
    padding:3.125rem 1rem;

    &.logged-in-footer {
        background-color: $LOGGED_IN_BG_COLOR;
    }

    .footer-items-title,
    .footer-item {
        color: $LOGGED_IN_TEXT;
        font-size: 14px;
        margin: 5px 0;
    }

    .footer-items-title {
        font-weight: bold;
    }

    .footer-link {
        text-decoration: none;
    }

    .pull-right {
        float: right;
        margin-right: 20px;
    }

    .pull-left {
        float: left;
        margin-left: 20px;
    }

    .footer-end-item {
        font-size: 10px;
    }

    .divider {
        margin: 1rem 0;
    }

    .social-media-container {
        margin: 10px 0;
        .social {
            height: 40px;
            background-repeat: no-repeat;
        }
    }
    .country-select{
        color:white  !important;
    }
    .country-select-border{
        border-bottom-color: white !important;
    }
}
