@import '../../../constants/constants.scss';

.home-card {
    justify-content: center;
    
    .MuiGrid-container {
        justify-content: center !important;
    }

    .outer-box{
        border-radius: .25rem!important;
        background-color: $PRIMARY_WHITE;
        border: 1px solid $CARD_BORDER_COLOR;
        min-height:250px;
        text-align: center;
        margin: 0 8%;	

        @media only screen and (max-width: $screen-sm) {
            margin: 0 16% 1.5rem;
        }
    }

    .img-fluid{
        height: 80px;
    }

    .theme-bg-color{
        background-color: #5D69FF !important;
        color: $PRIMARY_WHITE;
    }

    .box-shadow {
        -webkit-box-shadow: 5px 15px 60px -10px rgba(93,103,246,0.6);
        -moz-box-shadow: 5px 15px 60px -10px rgba(93,103,246,0.6);
        box-shadow: 5px 15px 60px -10px rgba(93,103,246,0.6);
    }
}  