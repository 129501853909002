@import '../../../../constants/constants.scss';

.syllabus .tools-strip {
    .interested-form {
        width: 360px;
        height: 525px;
        //border-radius: 10px;
        background-color: $PRIMARY_WHITE;
        box-shadow: 0px 0px 2px 2px $PRIMARY_GRAY;
        border: 1px solid $PRIMARY_GRAY;
        padding: 1rem;
        position: fixed;
        top: 70px;
        right: 5%;
        z-index: 10001;
        &.sticky {
            position: fixed;
            top: 70px;
            z-index: 10001;
        }
        &.above-footer {
            position: absolute;
            z-index: 0;
        }
        .subtitle,
        .heading-level-4 {
            color: $PRIMARY_COLOR;
        }
        .subtitle {
            font-size: 22px;
        }
        .regular-text {
            opacity: 0.7;
            text-align: justify;
            //padding-left:10px;
        }
        .input-text {
            padding: 0;
            width: 100%;
        }
        .MuiInputLabel-outlined {
            font-size: 12px;
        }
        .plus-cc {
            padding: 18px 5px;
        }
        .radio-text {
            padding: 4px;
            padding-left: 10px;
            text-align: justify;
        }
        .enquire-now-btn {
            color: #fff;
            width: 100%;
            border-radius: 30px;
            font-weight: bold;
            background-color: $PRIMARY_ORANGE;
            height: 40px;
        }
        .padding-left {
            padding-left: 10px;
        }
    }
}
