@import '../../../../constants/constants.scss';

.reviews-section {
    .ratings-section {
        background-color: $PRIMARY_COLOR;
        padding: 1rem;
        color: $PRIMARY_WHITE;
        .user-ratings {
            margin-top: 10px;
            margin-left: 10px;
        }
    }
    .review-carousel-container {
        .CarouselItem {
            min-height: 165px;
            @media only screen and (max-width: $screen-sm) {
                min-height: 300px;
            }
        }
        //height: 200px;
        padding: 1rem;
        .review-container {
            box-shadow: 0px 0px 2px 2px #f4f4f4;
            padding: 10px;
            margin: 10px;
            background-image: url(/images/course-page/quote-opening.png);
            background-repeat: no-repeat;
            background-size: 60px;
            background-position-x: 20px;
            background-position-y: 50px;
            background-color: $PRIMARY_WHITE;
        }
        .review-parent-name {
            font-size: 16px;
            font-weight: bold;
        }
        .user-pic-container {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            overflow: hidden;
            margin: auto;
        }
        .review-text {
            text-align : justify;
            font-size: 14px;
            line-height: 1.5;
        }

        .user-pic-container img {
            margin-left: auto;
            margin-right: auto;
            display: block;
        }

        @media only screen and (max-width: $screen-sm) {
            height: 400px;
            button {
                top: calc(50% - 100px) !important;
            }
        }
    }
}
