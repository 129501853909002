@import '../../constants/constants.scss';
.studies-section {
    background-color: $PRIMARY_WHITE;
    text-align: center;
    .MuiGrid-container {
        width: 80%;
        margin: 0 auto;
        flex-grow: 0 !important;
        position: relative;
    }

    .header {
        font-size: 30px;
        font-weight: 600;
        margin-top: 50px;
    }
    .f-14 {
        font-size: 14px;
        padding: 50px 0 20px;
    }
    .img {
        padding: 3rem 0;
        margin: 0 auto;
        max-width: 83.333333%;
    }

    .border {
        @media only screen and (min-width: $screen-sm) {
            border-right: 1px solid #dee2e6 !important;
            height: 70%;
            width: 1px;
            flex-grow: 0 !important;
            position: absolute;
            left: 50%;
            top: 20%;
        }
    }
}
