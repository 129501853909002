@import '../../../../constants/constants.scss';
.grid-row.course-info {
    background: $PRIMARY_WHITE;
    padding: 1rem;

    @media only screen and (max-width: $screen-sm) {
        margin-top: 25px;
    }

    h3.section-subtitle {
        font-weight: 400;
        padding-left: 1rem;
    }

    .MuiListItemAvatar-root {
        min-width: 30px;
    }

    .coursboxfree {
        background: $SECONDARY_BG_COLOR;
        padding: 0.938rem 1.875rem;
        margin: 1.25rem 0;

        h3.section-subtitle {
            font-weight: bold;
            padding-left: 0;
        }

        p {
            font-size: 1.375rem;
        }

        small {
            font-size: 0.625rem;
        }
    }

    .contact-info {
        padding: 0 1.875rem 0.938rem;

        img {
            max-width: 20px;
            max-height: 20px;
            margin-right: 4px;
        }
    }

    .course-details {
        margin: 0 1rem;

        li {
            border-bottom: 1px solid $SECONDARY_BG_COLOR;
            padding: 0.5rem 0;
            align-items: flex-start;
        }

        span {
            font-size: 0.75rem;
        }

        img {
            width: 20px;
            margin-top: 4px;
        }
    }
}
.course-guarantee {
    background: $PRIMARY_WHITE;
    padding: 1rem;
    margin-top: 0.5rem;

    img {
        max-width: 30px;
        margin-right: 10px;
    }
}
